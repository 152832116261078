
import { environment } from '../environments/environment';
export const data_source = {
  url: environment.apiUrl
};
/*
export const data_source = {
  //url: 'http://localhost:3005/',//ruta que apunta a la API en localhost
  url: 'https://proyectosdesarrollo.com:17013/', //ruta que apunta a la API del servidor
};
*/
