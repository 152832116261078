import { Observable } from 'rxjs';

export interface Rueda {
  id:number;
  id_carro:number;
  nombre: string;
  alias:string;
}
export  abstract class RuedasData {
  abstract getRuedas(): Observable<Rueda[]>;

}

