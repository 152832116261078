import { Observable } from 'rxjs';

export interface RegistroTemperaturaRueda {
  id:number;
  id_rueda:number;
  tempmaxrodado: Number;
  zonarodado: Number;
  fecha:Date;
  datetime:number;
}
export interface RegistroPerfilDescargas{
  id:number;
  id_rueda:number;
  fecha:Date;
  datetime:number;
  tiempo:number;
  temperatura_rod: Number;
  temperatura_ru: Number;
}
export interface ImagenTermica{
  id:number;
  id_rueda:number;
  fecha:Date;
  datetime:Number;

}
export  abstract class RegistrostemperaturaruedaData {
  abstract getRegistrostemperatura(): Observable<RegistroTemperaturaRueda[]>;
  abstract getRegistrostemperaturadate():  Observable<RegistroTemperaturaRueda[]>;//devuelve solo las fechas con registros
  abstract getregistroInd(idrueda, datetime):Observable<RegistroTemperaturaRueda []>
  abstract getUltimoregistroDate():Observable<RegistroTemperaturaRueda []>//fecha del ultimo registro
  abstract getRegistrosparamsbetwDates(idrueda:number, startdate:number, enddate:number):Observable<RegistroTemperaturaRueda []>//
  abstract getRegistrosdescargasbetwDates(idrueda:number, stardate:number, enddate:number):Observable<RegistroPerfilDescargas []>//para descargas



}

