import { filter } from 'rxjs/operators';
import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {  RegistrosdefectosData, RegistroDefectos, RegistroDefectosDate,RegistroDefectosParametros,RegistroDefectosDescargas } from '../data/registrodefectos';

@Injectable()
export class RegistrosDefectosService extends RegistrosdefectosData {

  private registrosdefectosdescargas: RegistroDefectosDescargas [] = [
    {"id": 1,"id_carro": 1, "fecha": new Date("2022-12-29T00:00:00"), "datetime":  new Date("2022-12-29T00:00:00").valueOf(),
    "tiempo": 0.0005, "aceleracion": 0.451, "waveletN1": -0.1244,
    "waveletN2": -0.1244,"waveletN3": -0.1244,"waveletN4": -0.1244,"waveletN5": -0.1244,
    "waveletN6": -0.1244,"waveletN7": -0.1244,"waveletN8": -0.1244,
    },
    {"id": 2,"id_carro": 1, "fecha": new Date("2022-12-29T01:00:00"), "datetime":  new Date("2022-12-29T01:00:00").valueOf(),
    "tiempo": 0.0010, "aceleracion": 0.451, "waveletN1": -0.1244,
    "waveletN2": -0.1244,"waveletN3": -0.1244,"waveletN4": -0.1244,"waveletN5": -0.1244,
    "waveletN6": -0.1244,"waveletN7": -0.1244,"waveletN8": -0.1244,
    },
    {"id": 3,"id_carro": 1, "fecha": new Date("2022-12-29T02:00:00"), "datetime": new Date("2022-12-29T02:00:00").valueOf(),
    "tiempo": 0.0015, "aceleracion": 0.451, "waveletN1": -0.1244,
    "waveletN2": -0.1244,"waveletN3": -0.1244,"waveletN4": -0.1244,"waveletN5": -0.1244,
    "waveletN6": -0.1244,"waveletN7": -0.1244,"waveletN8": -0.1244,
    },
    {"id": 4,"id_carro": 1, "fecha": new Date("2022-12-29T03:00:00"), "datetime":  new Date("2022-12-29T03:00:00").valueOf(),
    "tiempo": 0.0020, "aceleracion": 0.451, "waveletN1": -0.1244,
    "waveletN2": -0.1244,"waveletN3": -0.1244,"waveletN4": -0.1244,"waveletN5": -0.1244,
    "waveletN6": -0.1244,"waveletN7": -0.1244,"waveletN8": -0.1244,
    },
    {"id": 5,"id_carro": 1, "fecha": new Date("2022-12-29T04:00:00"), "datetime": new Date("2022-12-29T04:00:00").valueOf(),
    "tiempo": 0.0025, "aceleracion": 0.451, "waveletN1": -0.1244,
    "waveletN2": -0.1244,"waveletN3": -0.1244,"waveletN4": -0.1244,"waveletN5": -0.1244,
    "waveletN6": -0.1244,"waveletN7": -0.1244,"waveletN8": -0.1244,
    },
    {"id": 6,"id_carro": 1, "fecha": new Date("2022-12-30T00:00:00"), "datetime":  new Date("2022-12-30T00:00:00").valueOf(),
    "tiempo": 0.0005, "aceleracion": 0.451, "waveletN1": -0.1244,
    "waveletN2": -0.1244,"waveletN3": -0.1244,"waveletN4": -0.1244,"waveletN5": -0.1244,
    "waveletN6": -0.1244,"waveletN7": -0.1244,"waveletN8": -0.1244,
    },
    {"id": 7,"id_carro": 1, "fecha": new Date("2022-12-31T01:00:00"), "datetime": new Date("2022-12-31T01:00:00").valueOf(),
    "tiempo": 0.0010, "aceleracion": 0.451, "waveletN1": -0.1244,
    "waveletN2": -0.1244,"waveletN3": -0.1244,"waveletN4": -0.1244,"waveletN5": -0.1244,
    "waveletN6": -0.1244,"waveletN7": -0.1244,"waveletN8": -0.1244,
    },
    {"id": 8,"id_carro": 1, "fecha": new Date("2023-01-01T00:00:00"), "datetime": new Date("2023-01-01T00:00:00").valueOf(),
    "tiempo": 0.0005, "aceleracion": 0.451, "waveletN1": -0.1244,
    "waveletN2": -0.1244,"waveletN3": -0.1244,"waveletN4": -0.1244,"waveletN5": -0.1244,
    "waveletN6": -0.1244,"waveletN7": -0.1244,"waveletN8": -0.1244,
    },

  ]
  private registrosdefectosevparams: RegistroDefectosParametros [] = [
    {"id": 1,"id_carro": 1, "fecha": new Date("2022-12-26T00:00:00"), "datetime":  new Date("2022-12-26T00:00:00").valueOf(),
      "planorueda": 0.80, "shelling":0.666, "spalling": 0.777
    },
    {"id": 2,"id_carro": 1, "fecha": new Date("2022-12-27T00:00:00"), "datetime": new Date("2022-12-27T00:00:00").valueOf(),
    "planorueda": 0.80, "shelling":0.68, "spalling": 0.78
    },
    {"id": 3,"id_carro": 1,  "fecha": new Date("2022-12-28T00:00:00"), "datetime":  new Date("2022-12-28T00:00:00").valueOf(),
    "planorueda": 0.83, "shelling":0.70, "spalling": 0.79
    },
    {"id": 4,"id_carro": 1,  "fecha": new Date("2022-12-29T00:00:00"), "datetime": new Date("2022-12-29T00:00:00").valueOf(),
    "planorueda": 0.84, "shelling":0.71, "spalling": 0.81
    },
    {"id": 5,"id_carro": 1,  "fecha": new Date("2022-12-30T00:00:00"), "datetime": new Date("2022-12-30T00:00:00").valueOf(),
    "planorueda": 0.85, "shelling":0.72, "spalling": 0.82
    },
    {"id": 6,"id_carro": 1,  "fecha": new Date("2022-12-31T00:00:00"), "datetime": new Date("2022-12-31T00:00:00").valueOf(),
    "planorueda": 0.86, "shelling":0.73, "spalling": 0.83
    },
    {"id": 7,"id_carro": 1,  "fecha": new Date("2023-01-01T00:00:00"), "datetime": new Date("2023-01-01T00:00:00").valueOf(),
    "planorueda": 0.87, "shelling":0.75, "spalling": 0.84
    },
    {"id": 8,"id_carro": 1,  "fecha": new Date("2023-01-02T00:00:00"), "datetime": new Date("2023-01-02T00:00:00").valueOf(),
    "planorueda": 0.88, "shelling":0.76, "spalling": 0.85
    },
    {"id": 9,"id_carro": 1,  "fecha": new Date("2023-01-03T00:00:00"), "datetime": new Date("2023-01-03T00:00:00").valueOf(),
    "planorueda": 0.89, "shelling":0.78, "spalling": 0.89
    },
    {"id": 10,"id_carro": 1,  "fecha": new Date("2022-11-30T20:00:00"), "datetime": new Date("2022-11-30T20:00:00").valueOf(),
    "planorueda": 0.90, "shelling":0.80, "spalling": 0.95
    },
    {"id": 11,"id_carro": 2, "fecha": new Date("2022-12-17T03:00:00"), "datetime": new Date("2022-12-17T03:00:00").valueOf(),
    "planorueda": 0.94, "shelling":0.82, "spalling": 0.96
    },
    {"id": 12,"id_carro": 3, "fecha": new Date("2022-12-17T05:00:00"), "datetime": new Date("2022-12-17T05:00:00").valueOf(),
    "planorueda": 0.95, "shelling":0.83, "spalling": 0.97
    },
    {"id": 13, "id_carro": 3,"fecha": new Date("2022-12-17T08:00:00"), "datetime": new Date("2022-12-17T08:00:00").valueOf(),
    "planorueda": 0.98, "shelling":0.85, "spalling": 0.98
    },
  ]
  private registrosdefectos: RegistroDefectos[] =   [
    {"id": 1,"id_carro": 1, "fecha": new Date("2021-10-29T23:00:00"), "datetime":  new Date("2021-10-29T23:00:00").valueOf(), "registroswavelet": [
      {"rueda":1, "maxw3":1, "maxw4":4, "defecto":"plano rueda", "ids_acelerometros": "1-4" },
      {"rueda":2, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":3, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":4, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":5, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":6, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":7, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":8, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
    ]},
    {"id": 2,"id_carro": 1, "fecha": new Date("2022-11-29T07:00:00"), "datetime": new Date("2022-11-29T07:00:00").valueOf(), "registroswavelet": [
      {"rueda":1, "maxw3":2, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":2, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":3, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":4, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":5, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":6, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":7, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":8, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
    ]},
    {"id": 3,"id_carro": 1,  "fecha": new Date("2022-12-15T01:00:00"), "datetime":  new Date("2022-12-15T01:00:00").valueOf(), "registroswavelet": [
      {"rueda":1, "maxw3":3, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":2, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":3, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":4, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":5, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":6, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":7, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":8, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
    ]},
    {"id": 4,"id_carro": 1,  "fecha": new Date("2022-12-16T11:30:00"), "datetime": new Date("2022-12-16T11:30:00").valueOf(), "registroswavelet": [
      {"rueda":1, "maxw3":4, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":2, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":3, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":4, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":5, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":6, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":7, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":8, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
    ]},
    {"id": 5,"id_carro": 1,  "fecha": new Date("2022-12-17T10:30:00"), "datetime": new Date("2022-12-17T10:30:00").valueOf(), "registroswavelet": [
      {"rueda":1, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":2, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":3, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":4, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":5, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":6, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":7, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":8, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
    ]},
    {"id": 6,"id_carro": 1,  "fecha": new Date("2022-12-17T11:30:00"), "datetime": new Date("2022-12-17T11:30:00").valueOf(), "registroswavelet": [
      {"rueda":1, "maxw3":6, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":2, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":3, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":4, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":5, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":6, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":7, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":8, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
    ]},
    {"id": 7,"id_carro": 1,  "fecha": new Date("2022-12-18T11:30:00"), "datetime": new Date("2022-12-18T11:30:00").valueOf(), "registroswavelet": [
      {"rueda":1, "maxw3":7, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":2, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":3, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":4, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":5, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":6, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":7, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":8, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
    ]},
    {"id": 8,"id_carro": 1,  "fecha": new Date("2022-12-19T11:30:00"), "datetime": new Date("2022-12-19T11:30:00").valueOf(), "registroswavelet": [
      {"rueda":1, "maxw3":8, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":2, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":3, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":4, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":5, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":6, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":7, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":8, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
    ]},
    {"id": 9,"id_carro": 1,  "fecha": new Date("2022-11-30T02:00:00"), "datetime": new Date("2022-11-30T02:00:00").valueOf(), "registroswavelet": [
      {"rueda":1, "maxw3":9, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":2, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":3, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":4, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":5, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":6, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":7, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":8, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
    ]},
    {"id": 10,"id_carro": 1,  "fecha": new Date("2022-11-30T20:00:00"), "datetime": new Date("2022-11-30T20:00:00").valueOf(), "registroswavelet": [
      {"rueda":1, "maxw3":10, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":2, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":3, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":4, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":5, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":6, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":7, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":8, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
    ]},
    {"id": 11,"id_carro": 2, "fecha": new Date("2022-12-17T03:00:00"), "datetime": new Date("2022-12-17T03:00:00").valueOf(), "registroswavelet": [
      {"rueda":1, "maxw3":11, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":2, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":3, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":4, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":5, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":6, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":7, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":8, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
    ]},
    {"id": 12,"id_carro": 3, "fecha": new Date("2022-12-17T05:00:00"), "datetime": new Date("2022-12-17T05:00:00").valueOf(), "registroswavelet": [
      {"rueda":1, "maxw3":12, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":2, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":3, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":4, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":5, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":6, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":7, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":8, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
    ]},
    {"id": 13, "id_carro": 3,"fecha": new Date("2022-12-17T08:00:00"), "datetime": new Date("2022-12-17T08:00:00").valueOf(), "registroswavelet": [
      {"rueda":1, "maxw3":13, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":2, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":3, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":4, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "1-4" },
      {"rueda":5, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":6, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":7, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
      {"rueda":8, "maxw3":5, "maxw4":4, "defecto":"No", "ids_acelerometros": "5-8" },
    ]},
  ]
  getRegistrosdefectos(){
    return observableOf(this.registrosdefectos);
  }
  getRegistrosdefectosdate(): Observable<RegistroDefectosDate[]> {
    let registrosDate: RegistroDefectosDate [] = [];
    this.registrosdefectos.forEach(element => {
      registrosDate.push({"id":element.id, "id_carro": element.id_carro, "fecha": element.fecha, "datetime": element.datetime })
    });
    return observableOf(registrosDate);
  }
  getregistroByid(idregistro:number): Observable<RegistroDefectos []> {
    //console.log(idregistro);
    //console.log(this.registrosdefectos.filter(reg => reg.id == idregistro))
    return observableOf(this.registrosdefectos.filter(reg => reg.id == idregistro));
  }
  getregistroInd(idcarro:number, datetime:number): Observable<RegistroDefectos []> {
    return observableOf(this.registrosdefectos.filter(reg => reg.id_carro == idcarro && reg.datetime == datetime));
  }
  getUltimoregistroDate(): Observable<any> {
    let ultimoregistro;
    this.registrosdefectos.forEach(element => {
      if (!ultimoregistro){
        ultimoregistro = element;
      }
      else{
        if (element.datetime> ultimoregistro.datetime){
          element.datetime >= ultimoregistro.datetime;
          ultimoregistro = element;
        }
      }

    });
    let registro =  {"id_carro":ultimoregistro.id_carro, "fecha": ultimoregistro.fecha}
    return observableOf(registro);
  }
  getRegistrosparamsbetwDates(idcarro:number, startdate:number, enddate:number): Observable<RegistroDefectosParametros[]> {
    let array = this.registrosdefectosevparams.filter(reg => reg.id_carro == idcarro  && (reg.datetime  >= startdate && reg.datetime <= enddate))
    return observableOf(array);
  }
  getRegistrosdescargasbetwDates(idcarro: number, startdate: number, enddate: number): Observable<RegistroDefectosDescargas[]> {
    let array = this.registrosdefectosdescargas.filter(reg => reg.id_carro == idcarro  && (reg.datetime  >= startdate && reg.datetime <= enddate))
    return observableOf(array);
  }

}
