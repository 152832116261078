import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {  RegistrostemperaturaruedaData, RegistroTemperaturaRueda,RegistroPerfilDescargas } from '../data/registrotemperatura';

@Injectable()
export class RegistrosTemperaturaService extends RegistrostemperaturaruedaData {

  private registrostemperaturadescargas: RegistroPerfilDescargas[] = [
    {"id": 1,"tiempo": 0.0005,"id_rueda": 1,  "temperatura_rod": 26, "temperatura_ru": 1, "fecha": new Date("2022-12-29T01:00:00"), "datetime":new Date("2022-12-29T01:00:00").valueOf() },
    {"id": 2,"tiempo": 0.0010,"id_rueda": 1, "temperatura_rod": 22, "temperatura_ru": 1,"fecha": new Date("2022-12-29T02:00:00"), "datetime":new Date("2022-12-29T02:00:00").valueOf() },
    {"id": 3,"tiempo": 0.0015,"id_rueda": 1, "temperatura_rod": 29, "temperatura_ru": 8, "fecha": new Date("2022-12-29T03:00:00"), "datetime":new Date("2022-12-29T03:00:00").valueOf() },
    {"id": 4,"tiempo": 0.0020,"id_rueda": 1, "temperatura_rod": 26, "temperatura_ru": 1, "fecha": new Date("2022-12-29T04:00:00"), "datetime":new Date("2022-12-29T04:00:00").valueOf() },
    {"id": 5,"tiempo": 0.0025,"id_rueda": 1, "temperatura_rod": 22,"temperatura_ru": 4,"fecha": new Date("2022-12-29T05:00:00"), "datetime":new Date("2022-12-29T05:00:00").valueOf() },
    {"id": 6,"tiempo": 0.0030,"id_rueda": 1, "temperatura_rod": 24,"temperatura_ru": 3, "fecha": new Date("2022-12-29T06:00:00"), "datetime":new Date("2022-12-29T06:00:00").valueOf() },
    {"id": 7,"tiempo": 0.0035,"id_rueda": 1, "temperatura_rod": 27,"temperatura_ru": 1,"fecha": new Date("2022-12-29T07:00:00"), "datetime": new Date("2022-12-29T07:00:00").valueOf() },
    {"id": 8,"tiempo": 0.0040,"id_rueda": 1, "temperatura_rod": 20,"temperatura_ru": 8,"fecha": new Date("2022-12-29T08:00:00"), "datetime":new Date("2022-12-29T08:00:00").valueOf() },
    {"id": 9,"tiempo": 0.0045,"id_rueda": 1, "temperatura_rod": 27,"temperatura_ru": 1, "fecha": new Date("2022-12-29T09:00:00"), "datetime":new Date("2022-12-29T09:00:00").valueOf() },
    {"id": 10,"tiempo": 0.0005,"id_rueda": 1, "temperatura_rod": 29,"temperatura_ru": 10,"fecha": new Date("2022-12-30T00:00:00"), "datetime": new Date("2022-12-30T00:00:00").valueOf() },
    {"id": 11,"tiempo": 0.0010,"id_rueda": 1, "temperatura_rod": 35,"temperatura_ru": 1, "fecha": new Date("2022-12-30T01:00:00"), "datetime":new Date("2022-12-30T01:00:00").valueOf() },
    {"id": 12,"tiempo": 0.0015,"id_rueda": 1, "temperatura_rod": 40,"temperatura_ru": 12,"fecha": new Date("2022-12-30T02:00:00"), "datetime":new Date("2022-12-30T02:00:00").valueOf() },
    {"id": 13,"tiempo": 0.0020,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1, "fecha": new Date("2022-12-30T04:00:00"), "datetime":new Date("2022-12-30T04:00:00").valueOf() },
    {"id": 14,"tiempo": 0.0005,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1, "fecha": new Date("2022-12-31T00:00:00"), "datetime":new Date("2022-12-31T00:00:00").valueOf() },
    {"id": 15,"tiempo": 0.0010,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1,"fecha": new Date("2022-12-31T01:00:00"), "datetime":new Date("2022-12-31T01:00:00").valueOf() },
    {"id": 16,"tiempo": 0.0015,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1,"fecha": new Date("2022-12-31T02:00:00"), "datetime":new Date("2022-12-31T02:00:00").valueOf() },
    {"id": 17,"tiempo": 0.0025,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1,"fecha": new Date("2022-12-31T03:00:00"), "datetime":new Date("2022-12-31T03:00:00").valueOf() },
    {"id": 18,"tiempo": 0.0030,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1,"fecha": new Date("2022-12-31T04:00:00"), "datetime":new Date("2022-12-31T04:00:00").valueOf() },
    {"id": 19,"tiempo": 0.0035,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1,"fecha": new Date("2022-12-31T05:00:00"), "datetime":new Date("2022-12-31T05:00:00").valueOf() },
    {"id": 20,"tiempo": 0.0005,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1,"fecha": new Date("2023-01-01T00:00:00"), "datetime":new Date("2023-01-01T00:00:00").valueOf() },
    {"id": 21,"tiempo": 0.0010,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1,"fecha": new Date("2023-01-01T01:00:00"), "datetime":new Date("2023-01-01T01:00:00").valueOf() },
    {"id": 22,"tiempo": 0.0015,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1,"fecha": new Date("2023-01-01T02:00:00"), "datetime":new Date("2023-01-01T02:00:00").valueOf() },
    {"id": 23,"tiempo": 0.0020,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1,"fecha": new Date("2023-01-01T03:00:00"), "datetime":new Date("2023-01-01T03:00:00").valueOf() },
    {"id": 24,"tiempo": 0.0025,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1,"fecha": new Date("2023-01-01T04:00:00"), "datetime":new Date("2023-01-01T04:00:00").valueOf() },
    {"id": 25,"tiempo": 0.0030,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1,"fecha": new Date("2023-01-01T05:00:00"), "datetime":new Date("2023-01-01T05:00:00").valueOf() },
    {"id": 26,"tiempo": 0.0035,"id_rueda": 1, "temperatura_rod": 54,"temperatura_ru": 1,"fecha": new Date("2023-01-01T06:00:00"), "datetime":new Date("2023-01-01T06:00:00").valueOf() },

  ]
  private registrostemperaturaparams: RegistroTemperaturaRueda[] =   [
    {"id": 1,"id_rueda": 1,  "tempmaxrodado": 26, "zonarodado": 1, "fecha": new Date("2022-12-26T00:00:00"), "datetime": new Date("2022-12-26T00:00:00").valueOf()},
    {"id": 2,"id_rueda": 1, "tempmaxrodado": 22, "zonarodado": 1, "fecha": new Date("2022-12-27T00:00:00"), "datetime":new Date("2022-12-27T00:00:00").valueOf()},
    {"id": 3,"id_rueda": 1, "tempmaxrodado": 29, "zonarodado": 8, "fecha": new Date("2022-12-28T00:00:00"), "datetime": new Date("2022-12-28T00:00:00").valueOf()},
    {"id": 4,"id_rueda": 1, "tempmaxrodado": 26, "zonarodado": 1, "fecha": new Date("2022-12-29T00:00:00"), "datetime": new Date("2022-12-29T00:00:00").valueOf()},
    {"id": 5,"id_rueda": 1, "tempmaxrodado": 26,"zonarodado": 1, "fecha": new Date("2022-12-30T00:00:00"), "datetime":  new Date("2022-12-30T00:00:00").valueOf()},
    {"id": 6,"id_rueda": 1, "tempmaxrodado": 26,"zonarodado": 1, "fecha": new Date("2022-12-31T00:00:00"), "datetime": new Date("2022-12-31T00:00:00").valueOf()},
  ]

  private registrostemperatura: RegistroTemperaturaRueda[] =   [
    {"id": 1,"id_rueda": 1,  "tempmaxrodado": 26, "zonarodado": 1, "fecha": new Date("2021-10-29T23:00:00"), "datetime": new Date("2021-10-29T23:00:00").valueOf()},
    {"id": 2,"id_rueda": 1, "tempmaxrodado": 22, "zonarodado": 1, "fecha": new Date("2022-11-29T07:00:00"), "datetime": new Date("2022-11-29T07:00:00").valueOf()},
    {"id": 3,"id_rueda": 1, "tempmaxrodado": 29, "zonarodado": 8, "fecha": new Date("2022-12-15T01:00:00"), "datetime": new Date("2022-12-15T01:00:00").valueOf()},
    {"id": 4,"id_rueda": 1, "tempmaxrodado": 26, "zonarodado": 1, "fecha": new Date("2022-12-16T11:30:00"), "datetime": new Date("2022-12-16T11:30:00").valueOf()},
    {"id": 5,"id_rueda": 1, "tempmaxrodado": 26,"zonarodado": 1, "fecha": new Date("2022-11-30T02:00:00"), "datetime": new Date("2022-11-30T02:00:00").valueOf()},
    {"id": 6,"id_rueda": 1, "tempmaxrodado": 26,"zonarodado": 1, "fecha": new Date("2022-11-30T20:00:00"), "datetime": new Date("2022-11-30T20:00:00").valueOf()},
    {"id": 7,"id_rueda": 2,"tempmaxrodado": 26, "zonarodado": 3, "fecha": new Date("2022-12-17T03:00:00"), "datetime": new Date("2022-12-17T03:00:00").valueOf()},
    {"id": 8,"id_rueda": 3, "tempmaxrodado": 19,"zonarodado": 4, "fecha": new Date("2022-12-17T05:00:00"), "datetime": new Date("2022-12-17T05:00:00").valueOf()},
    {"id": 9, "id_rueda": 3,"tempmaxrodado": 129, "zonarodado": 5, "fecha": new Date("2022-12-17T08:00:00"), "datetime": new Date("2022-12-17T08:00:00").valueOf()},
    {"id": 10, "id_rueda": 4,"tempmaxrodado": 30, "zonarodado": 5, "fecha": new Date("2022-12-17T08:00:00"), "datetime": new Date("2022-12-17T08:00:00").valueOf()},
    {"id": 11, "id_rueda": 5,"tempmaxrodado": 50, "zonarodado": 5, "fecha": new Date("2022-12-17T08:00:00"), "datetime": new Date("2022-12-17T08:00:00").valueOf()},

  ]
  getRegistrostemperatura(): Observable<any> {
    return observableOf(this.registrostemperatura);
  }
  getRegistrostemperaturadate(): Observable<RegistroTemperaturaRueda[]> {
    let registrosDate: any [] = [];
    this.registrostemperatura.forEach(element => {
      registrosDate.push({"id":element.id, "id_rueda": element.id_rueda, "fecha": element.fecha, "datetime": element.datetime })
    });
    return observableOf(registrosDate);
  }
  getregistroInd(idrueda: any, datetime: any): Observable<RegistroTemperaturaRueda[]> {
    return observableOf(this.registrostemperatura.filter(reg => reg.id_rueda == idrueda && reg.datetime == datetime));
  }
  getUltimoregistroDate(): Observable<any> {
    let ultimoregistro;
    this.registrostemperatura.forEach(element => {
      if (!ultimoregistro){
        ultimoregistro = element;
      }
      else{
        if (element.datetime> ultimoregistro.datetime){
          element.datetime >= ultimoregistro.datetime;
          ultimoregistro = element;
        }
      }

    });
    let registro =  {"id_rueda":ultimoregistro.id_rueda, "fecha": ultimoregistro.fecha}
    return observableOf(registro);
  }
  getRegistrosparamsbetwDates(idrueda: number, startdate: number, enddate: number): Observable<RegistroTemperaturaRueda[]> {
    let array = this.registrostemperaturaparams.filter(reg => reg.id_rueda == idrueda  && (reg.datetime  >= startdate && reg.datetime <= enddate))
    return observableOf(array);
  }
  getRegistrosdescargasbetwDates(idrueda: number, startdate: number, enddate: number): Observable<RegistroPerfilDescargas[]> {
    let array = this.registrostemperaturadescargas.filter(reg => reg.id_rueda == idrueda  && (reg.datetime  >= startdate && reg.datetime <= enddate))
    return observableOf(array);
  }
}
