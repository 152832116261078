import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UsuarioService } from '../../../services/usuario.service';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy{

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;



  currentTheme = 'default';

  userMenu: any;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private usuarioService: UsuarioService,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private router: Router) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.user = { nombre: sessionStorage.getItem("NOMBRE"), email: sessionStorage.getItem("EMAIL"), token: sessionStorage.getItem("TOKEN"),tipo:"", descripcion:""}
    this.getTipoUsuario()
    this.userMenu = [ { title: 'Log out', link: "/auth/login"} ];
  }

  getTipoUsuario(){
    this.usuarioService.getTipousuario(this.user.nombre, this.user.token ).pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (data["data"]){
          this.user.tipo =data["data"].tipousuario;
          this.user.descripcion =data["data"].descripcion;
          this.userMenu = [ { title: 'Log out', icon: 'unlock-outline', link: "/auth/login"}, {title: this.user.tipo, icon:"person-outline"} ];

        }
      },
      error: (e) => console.error(e)
    });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome("/pages/inicio");
    this.router.navigate(['/pages/inicio']);
    /*
    this.menuService.navigateHome("/pages/registros/perfil-rueda");
    this.router.navigate(['/pages/registros/perfil-rueda']);
    */
    return false;
  }
}
