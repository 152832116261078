import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {  RegistrosperfilrawData, RegistroPerfilRaw } from '../data/registroperfilraw';

@Injectable()
export class RegistrosPerfilRawService extends RegistrosperfilrawData {
  private registrosperfilraw: RegistroPerfilRaw[] =   [
    {"id": 1,"id_rueda": 1,  "fecha": new Date("2021-10-29T23:00:00"), "datetime": new Date("2021-10-29T23:00:00").valueOf(),
     "data_teorica":[
        0,4,5.1,3.1,1.5,1.5,1.5,2,2.1,1.5,0
      ],
      "data_reconstruida":[
        0,4,5,3,1,1.5,1.5,2,2.5,1.4,0
      ]
    },
    {"id": 2,"id_rueda": 1,  "fecha": new Date("2022-11-29T07:00:00"), "datetime": new Date("2022-11-29T07:00:00").valueOf(),
     "data_teorica":[
        0,3,4.1,3.1,1.5,1.5,1.5,2,2.1,1.5,0
      ],
      "data_reconstruida":[
        0,3,4,3,1,1.5,1.5,2,2.5,1.4,0
      ]
    },
    {"id": 3,"id_rueda": 1,  "fecha": new Date("2022-12-15T01:00:00"), "datetime": new Date("2022-12-15T01:00:00").valueOf(),
    "data_teorica":[
       0,20,40.1,30.1,10.5,10.5,10.5,20,20.10,10.5,0
     ],
     "data_reconstruida":[
       0,30,40,30,10,10.5,10.5,20,20.5,10.4,0
     ]
   },
   {"id": 4,"id_rueda": 1,  "fecha":  new Date("2022-12-16T11:30:00"), "datetime": new Date("2022-12-16T11:30:00").valueOf(),
    "data_teorica":[
       0,20,30.1,30.1,10.5,10.5,10.5,20,20.10,10.5,0
     ],
     "data_reconstruida":[
       0,20,30,30,10,10.5,10.5,20,20.5,10.4,0
     ]
   },

   {"id": 5,"id_rueda": 1,  "fecha":  new Date("2022-12-17T10:30:00"), "datetime": new Date("2022-12-17T10:30:00").valueOf(),
   "data_teorica":[
      0,20,30.1,30.1,10.5,10.5,10.5,20,20.10,10.5,0
    ],
    "data_reconstruida":[
      0,20,30,30,10,10.5,10.5,20,20.5,10.4,0
    ]
   },

   {"id": 6,"id_rueda": 1,  "fecha":  new Date("2022-12-17T11:30:00"), "datetime": new Date("2022-12-17T11:30:00").valueOf(),
   "data_teorica":[
      0,20,30.1,30.1,10.5,10.5,10.5,20,20.10,10.5,0
    ],
    "data_reconstruida":[
      0,20,30,30,10,10.5,10.5,20,20.5,10.4,0
    ]
   },
   {"id": 7,"id_rueda": 1,  "fecha":  new Date("2022-12-18T11:30:00"), "datetime": new Date("2022-12-18T11:30:00").valueOf(),
   "data_teorica":[
      0,20,30.1,30.1,10.5,10.5,10.5,20,20.10,10.5,0
    ],
    "data_reconstruida":[
      0,20,30,30,10,10.5,10.5,20,20.5,10.4,0
    ]
   },
   {"id": 8,"id_rueda": 1,  "fecha":  new Date("2022-12-19T11:30:00"), "datetime": new Date("2022-12-19T11:30:00").valueOf(),
   "data_teorica":[
      0,20,30.1,30.1,10.5,10.5,10.5,20,20.10,10.5,0
    ],
    "data_reconstruida":[
      0,20,30,30,10,10.5,10.5,20,20.5,10.4,0
    ]
   },
   {"id": 9,"id_rueda": 1, "fecha": new Date("2022-11-30T02:00:00"), "datetime": new Date("2022-11-30T02:00:00").valueOf(),
    "data_teorica":[
       0,60,80.1,40.1,30.5,20.5,10.5,20,20.10,10.5,0
     ],
     "data_reconstruida":[
       0,60,80,40,27,20.5,10.5,20,20.5,10.4,0
     ]
   },
   {"id": 10,"id_rueda": 1, "fecha": new Date("2022-11-30T20:00:00"), "datetime": new Date("2022-11-30T20:00:00").valueOf(),
   "data_teorica":[
      0,60,80.1,40.1,30.5,20.5,10.5,20,20.10,10.5,0
    ],
    "data_reconstruida":[
      0,60,80,40,27,20.5,10.5,20,20.5,10.4,0
    ]
  },
  {"id": 11,"id_rueda": 2, "fecha": new Date("2022-12-17T03:00:00"), "datetime": new Date("2022-12-17T03:00:00").valueOf(),
   "data_teorica":[
      0,15,19.1,12.1,5.5,10.5,10.5,12,12.10,10.5,0
    ],
    "data_reconstruida":[
      0,15,18,12,10,10.5,5.5,12,13,10.4,0
    ]
  },
  {"id": 12,"id_rueda": 3,  "fecha": new Date("2022-12-17T05:00:00"), "datetime": new Date("2022-12-17T05:00:00").valueOf(),
   "data_teorica":[
      0,15,22.1,12.1,5.5,10.5,10.5,12,12.10,10.5,0
    ],
    "data_reconstruida":[
      0,15,21,12,10,10.5,5.5,12,13,10.4,0
    ]
  },
  {"id": 13,"id_rueda": 3, "fecha": new Date("2022-12-17T08:00:00"), "datetime": new Date("2022-12-17T08:00:00").valueOf(),
   "data_teorica":[
      0,15,24.1,12.1,5.5,10.5,10.5,12,12.10,10.5,0
    ],
    "data_reconstruida":[
      0,15,23,12,10,10.5,5.5,12,13,10.4,0
    ]
  },
  {"id": 14,"id_rueda": 4, "fecha": new Date("2022-12-17T08:00:00"), "datetime": new Date("2022-12-17T08:00:00").valueOf(),
    "data_teorica":[
      0,15,24.1,12.1,5.5,10.5,10.5,12,12.10,10.5,0
    ],
    "data_reconstruida":[
      0,15,23,12,10,10.5,5.5,12,13,10.4,0
    ]
  },
  {"id": 15,"id_rueda": 5, "fecha": new Date("2022-12-17T08:00:00"), "datetime": new Date("2022-12-17T08:00:00").valueOf(),
    "data_teorica":[
      0,15,24.1,12.1,5.5,10.5,10.5,12,12.10,10.5,0
    ],
    "data_reconstruida":[
      0,15,23,12,10,10.5,5.5,12,13,10.4,0
    ]
  },
  ]
  getRegistrosperfilraw(): Observable<any> {
    return observableOf(this.registrosperfilraw);
  }
  getRegistrosperfilrawByid(id_registroraw): Observable<any> {
    return observableOf(this.registrosperfilraw.filter(reg => reg.id == id_registroraw ));
  }
  getregistroInd(idrueda: any, datetime: any): Observable<RegistroPerfilRaw[]> {
    return observableOf(this.registrosperfilraw.filter(reg => reg.id_rueda == idrueda && reg.datetime == datetime));
  }


}
