import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {  RuedasData, Rueda } from '../data/ruedas';

@Injectable()
export class RuedasService extends RuedasData {
  private ruedas: Rueda[] =   [
    {"id": 1,"id_carro": 1, "nombre": "R00014", "alias": "rueda 1"},
    {"id": 2,"id_carro": 1, "nombre": "R00013","alias": "rueda 2"},
    {"id": 3,"id_carro": 2, "nombre": "R00017","alias": "rueda 3"},
    {"id": 4,"id_carro": 3, "nombre": "R00039","alias": "rueda 4"},
    {"id": 5, "id_carro": 3,"nombre": "R00045","alias": "rueda 5"},

  ]
  getRuedas(): Observable<any> {
    return observableOf(this.ruedas);
  }
}
