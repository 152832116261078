import { Observable } from 'rxjs';

export interface RegistroDefectos {
  id:number;
  id_carro:number;
  fecha:Date;
  datetime:number;
  registroswavelet:wavelet [];
}
export interface wavelet {
  rueda:number,
  maxw3:number,
  maxw4:number,
  defecto: string,
  ids_acelerometros:string
}
export interface RegistroDefectosDate {
  id:number;
  id_carro:number;
  fecha:Date;
  datetime:number;
}
export interface RegistroDefectosParametros{//interfaz para la pestaña "EVOLUCIÓN DE PARAMETROS"
  id:number;
  id_carro:number;
  fecha:Date;
  datetime:number;
  planorueda:number;
  shelling:number;
  spalling:number;
}
export interface RegistroDefectosDescargas{//interfaz para la pestaña "DESCARGAS"
  id:number;
  id_carro:number;
  fecha:Date;
  datetime:number;
  tiempo:number;
  aceleracion:number;
  waveletN1:number;
  waveletN2:number;
  waveletN3:number;
  waveletN4:number;
  waveletN5:number;
  waveletN6:number;
  waveletN7:number;
  waveletN8:number;

}
export  abstract class RegistrosdefectosData {
  abstract getRegistrosdefectos(): Observable<RegistroDefectos[]>;
  abstract getRegistrosdefectosdate(): Observable<RegistroDefectosDate[]>;
  abstract getregistroByid(idregistro):Observable<RegistroDefectos []>
  abstract getregistroInd(idcarro, datetime):Observable<RegistroDefectos []>//devuelve solo las fechas con registros
  abstract getUltimoregistroDate():Observable<RegistroDefectos []>//fecha del ultimo registro
  abstract getRegistrosparamsbetwDates(idcarro:number, stardate:number, enddate:number):Observable<RegistroDefectosParametros []>//fecha del ultimo registro
  abstract getRegistrosdescargasbetwDates(idcarro:number, stardate:number, enddate:number):Observable<RegistroDefectosDescargas []>//fecha del ultimo registro

}

