import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {  CarrosData, Carro } from '../data/carros';

@Injectable()
export class CarrosService extends CarrosData {
  private carros: Carro[] =   [
    {"id": 1, "nombre": "C007", "alias":"Carrito 1"},
    {"id": 2, "nombre": "C024", "alias":"Carrito 2"},
    {"id": 3, "nombre": "C051", "alias":"Carrito 3"}

  ]
  getCarros(): Observable<any> {
    return observableOf(this.carros);
  }
}
