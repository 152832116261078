import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {  RegistrosperfilruedaData, RegistroPerfilRueda,RegistroPerfilDescargas } from '../data/registroperfil';
import { RegistrosModule } from '../../pages/registros/registros.module';

@Injectable()
export class RegistrosPerfilService extends RegistrosperfilruedaData {

  private registrosperfildescargas: RegistroPerfilDescargas[] = [
    {"id": 1,"id_rueda": 1, "tiempo": 0.0005, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-29T01:00:00"), "datetime":new Date("2022-12-29T01:00:00").valueOf() },
    {"id": 2,"id_rueda": 1, "tiempo": 0.0010, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-29T02:00:00"), "datetime":new Date("2022-12-29T02:00:00").valueOf() },
    {"id": 3,"id_rueda": 1, "tiempo": 0.0015, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-29T03:00:00"), "datetime":new Date("2022-12-29T03:00:00").valueOf() },
    {"id": 4,"id_rueda": 1, "tiempo": 0.0020, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-29T04:00:00"), "datetime":new Date("2022-12-29T04:00:00").valueOf() },
    {"id": 5,"id_rueda": 1, "tiempo": 0.0025, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-29T05:00:00"), "datetime":new Date("2022-12-29T05:00:00").valueOf() },
    {"id": 6,"id_rueda": 1, "tiempo": 0.0030, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-29T06:00:00"), "datetime":new Date("2022-12-29T06:00:00").valueOf() },
    {"id": 7,"id_rueda": 1, "tiempo": 0.0035, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-29T07:00:00"), "datetime": new Date("2022-12-29T07:00:00").valueOf() },
    {"id": 8,"id_rueda": 1, "tiempo": 0.0040, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-29T08:00:00"), "datetime":new Date("2022-12-29T08:00:00").valueOf() },
    {"id": 9,"id_rueda": 1, "tiempo": 0.0045, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-29T09:00:00"), "datetime":new Date("2022-12-29T09:00:00").valueOf() },
    {"id": 10,"id_rueda": 1, "tiempo": 0.0005, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-30T00:00:00"), "datetime": new Date("2022-12-30T00:00:00").valueOf() },
    {"id": 11,"id_rueda": 1, "tiempo": 0.0010, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-30T01:00:00"), "datetime":new Date("2022-12-30T01:00:00").valueOf() },
    {"id": 12,"id_rueda": 1, "tiempo": 0.0015, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-30T02:00:00"), "datetime":new Date("2022-12-30T02:00:00").valueOf() },
    {"id": 13,"id_rueda": 1, "tiempo": 0.0020, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-30T03:00:00"), "datetime":new Date("2022-12-30T03:00:00").valueOf() },
    {"id": 14,"id_rueda": 1, "tiempo": 0.0025, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-30T04:00:00"), "datetime":new Date("2022-12-30T04:00:00").valueOf() },
    {"id": 15,"id_rueda": 1, "tiempo": 0.0030, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-31T00:00:00"), "datetime":new Date("2022-12-31T00:00:00").valueOf() },
    {"id": 16,"id_rueda": 1, "tiempo": 0.0035, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-31T01:00:00"), "datetime":new Date("2022-12-31T01:00:00").valueOf() },
    {"id": 17,"id_rueda": 1, "tiempo": 0.0040, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-31T02:00:00"), "datetime":new Date("2022-12-31T02:00:00").valueOf() },
    {"id": 18,"id_rueda": 1, "tiempo": 0.0045, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-31T03:00:00"), "datetime":new Date("2022-12-31T03:00:00").valueOf() },
    {"id": 19,"id_rueda": 1, "tiempo": 0.0050, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-31T04:00:00"), "datetime":new Date("2022-12-31T04:00:00").valueOf() },
    {"id": 20,"id_rueda": 1, "tiempo": 0.0055, "altura": 5, "ancho": 26, "pendiente": 1, "fecha": new Date("2022-12-31T05:00:00"), "datetime":new Date("2022-12-31T05:00:00").valueOf() },



  ]
  private registrosperfilparams: RegistroPerfilRueda[] =   [
    {"id": 1,"id_rueda": 1, "id_registroraw": 1, "alturapes": 5, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2022-12-26T00:00:00"), "datetime":new Date("2022-12-26T00:00:00").valueOf() },
    {"id": 2,"id_rueda": 1, "id_registroraw": 2, "alturapes": 5, "anchopes": 22, "pendientepes": 1, "fecha": new Date("2022-12-27T00:00:00"), "datetime": new Date("2022-12-27T00:00:00").valueOf()},
    {"id": 3,"id_rueda": 1, "id_registroraw": 3, "alturapes": 31, "anchopes": 29, "pendientepes": 8, "fecha": new Date("2022-12-28T00:00:00"), "datetime":new Date("2022-12-28T00:00:00").valueOf() },
    {"id": 4,"id_rueda": 1, "id_registroraw": 4, "alturapes": 22, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2022-12-29T00:00:00"), "datetime":new Date("2022-12-29T00:00:00").valueOf() },

    {"id": 5,"id_rueda": 1, "id_registroraw": 5, "alturapes": 22, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2022-12-30T00:00:00"), "datetime":new Date("2022-12-30T00:00:00").valueOf() },
    {"id": 6,"id_rueda": 1, "id_registroraw": 6, "alturapes": 22, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2022-12-31T00:00:00"), "datetime": new Date("2022-12-31T00:00:00").valueOf() },
    {"id": 7,"id_rueda": 1, "id_registroraw": 7, "alturapes": 22, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2023-01-01T00:00:00"), "datetime":new Date("2023-01-01T00:00:00").valueOf() },
    {"id": 8,"id_rueda": 1, "id_registroraw": 8, "alturapes": 22, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2023-01-02T00:00:00"), "datetime":new Date("2023-01-02T00:00:00").valueOf() },
    {"id": 9,"id_rueda": 1, "id_registroraw": 9, "alturapes": 87, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2023-01-03T00:00:00"), "datetime":new Date("2023-01-03T00:00:00").valueOf() },

  ]
  private registrosperfil: RegistroPerfilRueda[] =   [
    {"id": 1,"id_rueda": 1, "id_registroraw": 1, "alturapes": 5, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2021-10-29T23:00:00"), "datetime":new Date("2021-10-29T23:00:00").valueOf() },
    {"id": 2,"id_rueda": 1, "id_registroraw": 2, "alturapes": 5, "anchopes": 22, "pendientepes": 1, "fecha": new Date("2022-11-29T07:00:00"), "datetime": new Date("2022-11-29T07:00:00").valueOf()},
    {"id": 3,"id_rueda": 1, "id_registroraw": 3, "alturapes": 31, "anchopes": 29, "pendientepes": 8, "fecha": new Date("2022-12-15T01:00:00"), "datetime":new Date("2022-12-15T01:00:00").valueOf() },
    {"id": 4,"id_rueda": 1, "id_registroraw": 4, "alturapes": 22, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2022-12-16T11:30:00"), "datetime":new Date("2022-12-16T11:30:00").valueOf() },

    {"id": 5,"id_rueda": 1, "id_registroraw": 5, "alturapes": 22, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2022-12-17T10:30:00"), "datetime":new Date("2022-12-17T10:30:00").valueOf() },
    {"id": 6,"id_rueda": 1, "id_registroraw": 6, "alturapes": 22, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2022-12-17T11:30:00"), "datetime":new Date("2022-12-17T11:30:00").valueOf() },
    {"id": 7,"id_rueda": 1, "id_registroraw": 7, "alturapes": 22, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2022-12-18T11:30:00"), "datetime":new Date("2022-12-18T11:30:00").valueOf() },
    {"id": 8,"id_rueda": 1, "id_registroraw": 8, "alturapes": 25.425, "anchopes": 28.175, "pendientepes": 15.145, "fecha": new Date("2022-12-19T11:30:00"), "datetime":new Date("2022-12-19T11:30:00").valueOf() },

    {"id": 9,"id_rueda": 1, "id_registroraw": 9, "alturapes": 87, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2022-11-30T02:00:00"), "datetime":new Date("2022-11-30T02:00:00").valueOf() },
    {"id": 10,"id_rueda": 1, "id_registroraw": 10, "alturapes": 87, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2022-11-30T20:00:00"), "datetime":new Date("2022-11-30T20:00:00").valueOf() },
    {"id": 11,"id_rueda": 2,"id_registroraw": 11, "alturapes": 18, "anchopes": 19, "pendientepes": 3, "fecha": new Date("2022-12-17T03:00:00"), "datetime": new Date("2022-12-17T03:00:00").valueOf()},
    {"id": 12,"id_rueda": 3,"id_registroraw": 12, "alturapes": 22, "anchopes": 29, "pendientepes": 4, "fecha": new Date("2022-12-17T05:00:00"), "datetime": new Date("2022-12-17T05:00:00").valueOf()},
    {"id": 13, "id_rueda": 3,"id_registroraw": 13,"alturapes": 23, "anchopes": 23, "pendientepes": 5, "fecha": new Date("2022-12-17T08:00:00"), "datetime": new Date("2022-12-17T08:00:00").valueOf()},
    {"id": 14, "id_rueda": 4,"id_registroraw": 14,"alturapes": 14, "anchopes": 23, "pendientepes": 5, "fecha": new Date("2022-12-17T08:00:00"), "datetime": new Date("2022-12-17T08:00:00").valueOf()},
    {"id": 15, "id_rueda": 5,"id_registroraw": 15,"alturapes": 15, "anchopes": 23, "pendientepes": 5, "fecha": new Date("2022-12-17T08:00:00"), "datetime": new Date("2022-12-17T08:00:00").valueOf()},

  ]
  getRegistrosperfil(): Observable<any> {
    return observableOf(this.registrosperfil);
  }
  getRegistrosDatedperfilByid(idrueda, startdate:Date, enddate:Date, dates): Observable<any>{
    let arrayDates = [];
    let regs = this.registrosperfil.filter(reg => reg.id_rueda == idrueda );
    //console.log(regs)
    let regsdated = regs.filter(reg => {
      return (reg.fecha.getTime() >= startdate.getTime() && reg.fecha.getTime() <= enddate.getTime());
    })
    dates.forEach(element => {
      //console.log(element.getDate());
      let registrodia = regsdated.forEach(reg => {
        //console.log(reg);
        //console.log("Reg fecha: " + reg.fecha + " >= date: " + element + " ")
        //console.log("Reg fecha: " + reg.fecha.getTime() + " >= date: " + element.getTime())

        if (reg.fecha.getTime() >= element.getTime() && reg.fecha.getTime() <= (element.getTime() + 86400000)){
        }
        return reg.fecha.getTime() >= element.getTime() && reg.fecha.getTime() <= (element.getTime() + 86400000);
      });
    });
    return observableOf(regsdated);
  }
  getRegistrosperfildate(): Observable<RegistroPerfilRueda[]> {
    let registrosDate: any [] = [];
    this.registrosperfil.forEach(element => {
      registrosDate.push({"id":element.id, "id_rueda": element.id_rueda, "fecha": element.fecha, "datetime": element.datetime })
    });
    return observableOf(registrosDate);
  }
  getregistroInd(idrueda: any, datetime: any): Observable<RegistroPerfilRueda[]> {
    return observableOf(this.registrosperfil.filter(reg => reg.id_rueda == idrueda && reg.datetime == datetime));
  }
  getUltimoregistroDate(): Observable<any> {
    let ultimoregistro;
    this.registrosperfil.forEach(element => {
      if (!ultimoregistro){
        ultimoregistro = element;
      }
      else{
        if (element.datetime> ultimoregistro.datetime){
          element.datetime >= ultimoregistro.datetime;
          ultimoregistro = element;
        }
      }

    });
    let registro =  {"id_rueda":ultimoregistro.id_rueda, "fecha": ultimoregistro.fecha}
    return observableOf(registro);
  }
  getRegistrosparamsbetwDates(idrueda: number, startdate: number, enddate: number): Observable<RegistroPerfilRueda[]> {
    let array = this.registrosperfilparams.filter(reg => reg.id_rueda == idrueda  && (reg.datetime  >= startdate && reg.datetime <= enddate))
    return observableOf(array);
  }
  getRegistrosdescargasbetwDates(idrueda: number, startdate: number, enddate: number): Observable<RegistroPerfilDescargas[]> {

    let array = this.registrosperfildescargas.filter(reg => reg.id_rueda == idrueda  && (reg.datetime  >= startdate && reg.datetime <= enddate))
    return observableOf(array);
  }
}
