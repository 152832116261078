import { Observable } from 'rxjs';
export interface Alerta {
  id:number;
  level: number;
  id_carro:number;
  id_rueda:number;
  alturapes: Number;
  anchopes: Number;
  pendientepes: Number;
  fecha:Date;
  datetime:number;
}
export interface AlertaTemperatura {
  id:number;
  level: number;
  id_carro:number;
  id_rueda:number;
  tempmaxrodado: Number;
  zonarodado: Number;
  fecha:Date;
  datetime:number;
}
export interface AlertaDefecto {
  id:number;
  level: number;
  id_carro:number;
  id_rueda:number;
  t_defecto:string;
  acelerometro:number;
  fecha:Date;
  datetime:number;
}
export  abstract class AlertasData {
  abstract getAlertas(): Observable<Alerta[]>;
  abstract getAlertasTemperatura(): Observable<AlertaTemperatura[]>;
  abstract getAlertasDefectos():Observable<AlertaDefecto[]>;
}

