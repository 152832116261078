import { Observable } from 'rxjs';

export interface Carro {
  id:number;
  nombre: string;
  alias:string;
}
export  abstract class CarrosData {
  abstract getCarros(): Observable<Carro[]>;

}

