import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {  AlertasData, Alerta, AlertaTemperatura, AlertaDefecto } from '../data/alertas';

@Injectable()
export class AlertasService extends AlertasData {
  private alertasperfil: Alerta[] =   [
    {"id": 1, "level": 1, "id_carro":1, "id_rueda": 1,  "alturapes": 5, "anchopes": 26, "pendientepes": 1, "fecha": new Date("2021-10-29T23:00:00"), "datetime": 1671115576},
    {"id": 2, "level": 2, "id_carro":2,"id_rueda": 3, "alturapes": 22, "anchopes": 29, "pendientepes": 4, "fecha": new Date("2022-12-17T05:00:00"), "datetime": 1671115576},
  ]
  private alertastemperatura: AlertaTemperatura[] =   [
    {"id": 1, "level": 1, "id_carro":1, "id_rueda": 1,"tempmaxrodado": 26, "zonarodado": 1, "fecha": new Date("2021-10-29T23:00:00"), "datetime": 1671115576},
    {"id": 2, "level": 2, "id_carro":3,"id_rueda": 3, "tempmaxrodado": 19, "zonarodado": 4, "fecha": new Date("2022-12-17T05:00:00"), "datetime": 1671115576},
    {"id": 3, "level": 1, "id_carro":3,"id_rueda": 3, "tempmaxrodado": 23, "zonarodado": 5, "fecha": new Date("2022-12-17T08:00:00"), "datetime": 1671115576},
  ]
  private alertasdefectos: AlertaDefecto[] =   [
    {"id": 1, "level": 1, "id_carro":1, "id_rueda":1 ,"t_defecto": "plano rueda", "acelerometro":1, "fecha": new Date("2021-10-29T23:00:00"), "datetime": new Date("2021-10-29T23:00:00").valueOf()}
  ]
  getAlertas(): Observable<any> {
    return observableOf(this.alertasperfil);
  }
  getAlertasTemperatura(): Observable<any> {
    return observableOf(this.alertastemperatura);
  }
  getAlertasDefectos(): Observable<any>{
    return observableOf(this.alertasdefectos);
  }

}
