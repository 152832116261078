import {Injectable} from '@angular/core';
import { Router} from '@angular/router';
import {NbAuthService} from '@nebular/auth';
import { Subject } from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

//import {data_source} from '../../../environments/data.source';
@Injectable()
export class AuthService {
    private destroy$: Subject<void> = new Subject<void>();
    tipousuario:string;
    public token: string;
    //public token: string = data_source.token;
    constructor(private router: Router) {}
    login( email:string, nombre:string , token: string, tipousuario:string) {
        this.tipousuario=tipousuario;
        this.token = token;
        this.router.navigate(['/pages']);
        sessionStorage.setItem('TOKEN', token);
        sessionStorage.setItem('EMAIL',email );
        sessionStorage.setItem('NOMBRE', nombre);

    }
    logout() {
        this.token = null;
        this.router.navigate(['/auth/login']);
    }
    isAuthenticated(redirect: boolean = false) {
      if (sessionStorage.getItem('TOKEN')==null ||sessionStorage.getItem('TOKEN')=="null" ){
        this.router.navigate(['/auth/login']);
        return false;
      }
        return true;
    }


    gToken() {
        return this.token == null || this.token == 'true' ? null : this.token;
    }
}
