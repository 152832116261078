import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';//conexión http
import { Observable } from 'rxjs';//sirve para manejar operaciones asincronas
import { data_source } from '../../environments/data_source';
const CryptoJS = require("crypto-js");

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const baseUrl = data_source.url + 'users';
@Injectable({
  providedIn: 'root'
})

export class UsuarioService {

  tipousuario:string;
  constructor(private http: HttpClient) { }

  login(email:string, password:string,latitud:number,longitud:number, sistemaoperativo:string, navegador:string ): Observable<any[]> {
    password = CryptoJS.MD5(password).toString();
    return this.http.post<any[]>(baseUrl+ "/login", {
      email: email,
      password:password,
      latitud:latitud,
      longitud:longitud,
      sistemaoperativo:sistemaoperativo,
      navegador:navegador

    }, httpOptions);
  }
   informacionconexionusuario(idusuario:number): Observable<any[]> {
    return this.http.post<any[]>(baseUrl+ "/getinfoconexionusuarios", {
      idusuario: idusuario,
    }, httpOptions);
  }
  infoprincipalusuarios(): Observable<any[]> {
    return this.http.get<any[]>(baseUrl+ "/getusuarios")
  }
  getTipousuario(nombre:string, token:string): Observable<any[]> {
    return this.http.post<any[]>(baseUrl+ "/gettipousuario", {
      nombre: nombre,
      token:token
    }, httpOptions);
  }
  desconectarUsuario(email:string, idusuario:number): Observable<any[]> {
    return this.http.post<any[]>(baseUrl+ "/desconectarusuario", {
      email: email,
      idusuario:idusuario
    }, httpOptions);
  }

}
