
<div class="contenedor">
<h1 id="title" class="title">Codelco. <br> Plataforma digital de monitorización de elementos rodantes</h1>
<p class="sub-title">Por favor, introduzca sus credenciales.</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>Error!</b></p>
    <ul class="alert-message-list">
        <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
    <p class="alert-title"><b>Alert!</b></p>
    <ul class="alert-message-list">
        <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

    <div class="form-control-group">
        <label class="label" for="input-email">E-mail:</label>
        <input nbInput fullWidth [(ngModel)]="user.email" #email="ngModel" name="email" id="input-email" pattern=".+@.+\..+" placeholder="E-mail" fieldSize="large" autofocus [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''" [required]="getConfigValue('forms.validation.email.required')"
            [attr.aria-invalid]="email.invalid && email.touched ? true : null">
        <ng-container *ngIf="email.invalid && email.touched">
            <p class="caption status-danger" *ngIf="email.errors?.required">
                E-mail:
            </p>
            <p class="caption status-danger" *ngIf="email.errors?.pattern">
                E-mail syntax error
            </p>
        </ng-container>
    </div>
    <div class="form-control-group">
      <span class="label-with-link">
    <label class="label" for="input-password">Contraseña:</label>
    <!-- <a class="forgot-password caption-2" routerLink="../request-password">Forgot Password?</a> -->
  </span>
      <input nbInput fullWidth [(ngModel)]="user.password" #password="ngModel" name="password" type="password" id="input-password" placeholder="Contraseña" fieldSize="large" [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''" [required]="getConfigValue('forms.validation.password.required')"
          [minlength]="getConfigValue('forms.validation.password.minLength')" [maxlength]="getConfigValue('forms.validation.password.maxLength')" [attr.aria-invalid]="password.invalid && password.touched ? true : null">
      <ng-container *ngIf="password.invalid && password.touched ">
          <p class="caption status-danger" *ngIf="password.errors?.required">
              Contraseña
          </p>
          <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
              La contraseña debe contener: {{ getConfigValue('forms.validation.password.minLength') }} hasta {{ getConfigValue('forms.validation.password.maxLength') }} caracteres
          </p>
      </ng-container>
  </div>

  <div class="form-control-group accept-group">
      <re-captcha class ="recaptcha" nbInput [(ngModel)]="user.captcha" #captcha="ngModel" name="captcha" required siteKey="6Ld5PGAUAAAAAPd0fBYGrLRv1CRnm1UrAMci5PP6"></re-captcha>
  </div>
    <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || !form.valid" [class.btn-pulse]="submitted">
    LOG IN
  </button>

</form>
</div>
