<div class="header-container headerizq">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><strong>  Monitoreo del material rodante</strong></a>
    <a class="logo-responsive" href="#" (click)="navigateHome()"><strong>  Monitoreo del <br>m.rodante</strong></a>
  </div>

</div>

<div class="header-container headerder">
  <div class="imagencoreal"></div>
  <div class="imagencorealresponsive"></div>
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
              [onlyPicture]="userPictureOnly"
              [name]="user?.nombre"
              [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
