import { Observable } from 'rxjs';

export interface RegistroPerfilRueda {
  id:number;
  id_rueda:number;
  id_registroraw:number;
  alturapes: Number;
  anchopes: Number;
  pendientepes: Number;
  fecha:Date;
  datetime:number;
}
export interface RegistroPerfilDescargas{
  id:number;
  id_rueda:number;
  fecha:Date;
  datetime:number;
  tiempo:number;
  ancho: number;
  altura: number;
  pendiente: number;

}
export  abstract class RegistrosperfilruedaData {
  abstract getRegistrosperfil(): Observable<RegistroPerfilRueda[]>;
  abstract getRegistrosDatedperfilByid(id_rueda:Number, startdate:Date, enddate:Date, arrayFechas):  Observable<RegistroPerfilRueda[]>;
  abstract getRegistrosperfildate():  Observable<RegistroPerfilRueda[]>;//devuelve solo las fechas con registros
  abstract getregistroInd(idrueda, datetime):Observable<RegistroPerfilRueda []>
  abstract getUltimoregistroDate():Observable<RegistroPerfilRueda []>//fecha del ultimo registro
  abstract getRegistrosparamsbetwDates(idrueda:number, startdate:number, enddate:number):Observable<RegistroPerfilRueda []>//fecha del ultimo registro
  abstract getRegistrosdescargasbetwDates(idrueda:number, stardate:number, enddate:number):Observable<RegistroPerfilDescargas []>//fecha del ultimo registro


}

