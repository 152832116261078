import { Observable } from 'rxjs';

export interface RegistroPerfilRaw {
  id:number;
  id_rueda:number;
  fecha:Date;
  datetime:number;
  data_teorica: number [];
  data_reconstruida: number [];

}
export  abstract class RegistrosperfilrawData {
  abstract getRegistrosperfilraw(): Observable<RegistroPerfilRaw[]>;
  abstract getRegistrosperfilrawByid(id_registroraw): Observable<RegistroPerfilRaw>;
  abstract getregistroInd(idrueda, datetime):Observable<RegistroPerfilRaw []>

}

